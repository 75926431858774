import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";

export default function CityTemplate({ data, pageContext, location }) {
  // console.log({ pageContext });
  // console.log({ data });
  // console.log({ location });

  const listings = data.listings.nodes;

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }

  // const areas = listings
  //   .map((listing) => listing.data.Area)
  //   .filter(onlyUnique)
  //   .sort();

  // const areaSlugs = listings
  //   .map((listing) => listing.data.Area_Slug)
  //   .filter(onlyUnique)
  //   .sort();

  const { cityFullName, cityDisplayName, citySlug, cityAreas, cityAreaSlugs } =
    pageContext;
  return (
    <>
      <section className="block">
        <ul className="breadcrumb">
          <li>
            <Link to="/">&#8962; Home</Link>
          </li>
        </ul>
      </section>
      <section className="block">
        <ul className="big-link-list">
          <li>{cityDisplayName}</li>
        </ul>
      </section>
      <section className="block">
        <ul className="medium-link-list">
          {cityAreas.map((area, i) => (
            <li key={`${citySlug}-${cityAreaSlugs[i]}-${i}`}>
              <Link to={`/${citySlug}/${cityAreaSlugs[i]}/`}>{area}</Link>
            </li>
          ))}
        </ul>
      </section>
      <section className="block">
        <ul className="link-list listing-list">
          {listings.map(
            (listing, i) =>
              !listing.data.Skip && (
                <li key={i}>
                  <Link to={`/${citySlug}/${listing.data.CID}/`}>
                    <span className="primary">
                      {listing.data.Name}
                      {listing.data.Must && <span className="must">●</span>}
                    </span>
                    <span className="secondary">
                      {listing.data.Korean_Name}
                    </span>
                  </Link>
                </li>
              )
          )}
        </ul>
      </section>
    </>
  );
}

export const query = graphql`
  query ($cityFullName: String!) {
    listings: allAirtable(
      filter: { data: { Publish: { eq: true }, City: { eq: $cityFullName } } }
      sort: { order: ASC, fields: data___Name }
    ) {
      nodes {
        data {
          Place_ID
          Name
          Korean_Name
          Area
          Area_Slug
          City
          CID
          Must
          Skip
          Publish
        }
      }
    }
  }
`;
